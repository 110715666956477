import React, { ReactChild, ReactChildren } from "react";
import profilePic from "@/img/ico/profile_pic.png"
import menuDots from "@/img/ico/menu_dots.svg"

interface Chat {
  className?: string,
  parsonName?: string
}
const Chat = ({
  className,
  personName
} : Chat) => {

  return (
    <div className={`${className} Chat`}>

      <div className="Chat__top">
        <img className="profilePic" src={profilePic} />
        <p>
          <strong>{personName}</strong>
          <span>shop owner</span>
        </p>
        <img src={menuDots} />
      </div>
      <div className="Chat__bubble">
        <p>
          The installation was a piece of cake!!! 🥳 Like really. 🔥🔥🔥😀
        </p>
        </div>
      </div>
  )
};

export default Chat;
