import Button from "@/components/Button/button";
import "@/components/Button/button.scss";
import Chat from "@/components/Chat/Chat";
import Col from "@/components/Col/Col";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import Section from "@/components/Section/Section";
import play_button from "@/img/ico/play__button.svg";
import logo_1 from "@/img/logos/logo_1.jpg";
import logo_2 from "@/img/logos/logo_2.jpg";
import logo_3 from "@/img/logos/logo_3.jpg";
import logo_4 from "@/img/logos/logo_4.jpg";
import logo_5 from "@/img/logos/logo_5.jpg";
import logo_6 from "@/img/logos/logo_6.jpg";
import sign from "@/img/sign.png";
import * as React from "react";
import "../../App.scss";

const Walkthrough = () => (
  <main>
    <Header alwaysFixed></Header>

    <Section className="Videohero">
      <div className="Videohero__headline">
        <h6>Join me on</h6>
        <h1>Ecomtrack Walkthrough</h1>
      </div>

      <div className="Videohero__block">
        {/*  <video className="Videohero__block__video" controls width="100%" height="100%" autoPlay>
          <source src={video}></source>
        </video> */}

        <iframe
          className="Videohero__block__video"
          src="https://player.vimeo.com/video/708103578?h=d4840d908f"
          width="900"
          height="510"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen></iframe>

        <Chat personName="Alysa"></Chat>
        <img className="Videohero__block__play" src={play_button} />
        <div className="Videohero__block__outline"></div>
        <div className="Videohero__block__bottom">
          <h3>
            The most accurate tracking tool for scaling ecom stores fast and
            with certainty.
          </h3>
          <p>
            <strong>Vaclav Tomanec</strong>
            <span>CEO, Ecomtrack</span>
          </p>
          <img className="sign" src={sign} />
        </div>
      </div>
    </Section>

    <Section className="testimonial__logos testimonial__logosBoxed">
      <h3>
        Trusted by <span className="violet">100+ clients</span> all over
        the&nbsp;world{" "}
      </h3>
      <div className="testimonial__logos__row">
        <img
          src={logo_1}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="200"
          data-sal-easing="ease-out"
        />
        <img
          src={logo_2}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="300"
          data-sal-easing="ease-out"
        />
        <img
          src={logo_4}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="400"
          data-sal-easing="ease-out"
        />
        <img
          src={logo_5}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="500"
          data-sal-easing="ease-out"
        />
        <img
          src={logo_3}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="600"
          data-sal-easing="ease-out"
        />
        <img
          src={logo_6}
          data-sal="slide-up"
          data-sal-duration="550"
          data-sal-delay="700"
          data-sal-easing="ease-out"
        />
      </div>
    </Section>

<Section>
      <div className="prizing__ready">
        <Col size="50">
          <h3>Ready to start tracking?</h3>
        </Col>
        <Col size="50">
          <Button
            {...{
              variant: "blue",
              link: "https://app.ecomtrack.io/register",
              text: "Start tracking",
            }}
          />
        </Col>
      </div>
    </Section>

    <Footer colFlex></Footer>
  </main>
);

export default Walkthrough;
